import { MIXPANEL_TOKEN, useAppContext } from "context/AppContext";
import mixpanel, { type Dict } from "mixpanel-browser";
import { useCallback, useEffect } from "react";
import { onOwnwellDotCom } from "./onOwnwellDotCom";
import { sendErrorToWebhook } from "./sendWebhook";
import { throttleFunctionByArgs } from "./throttleFunctionByArgs";

export const MIXPANEL_API_HOST = "https://www.ownwell.com/mp";

export const useEventTracker_DEPRECATED = (eventName: string, data?: any) => {
	const { mixpanelInitialized, setMixpanelInitialized } = useAppContext();

	return useCallback(
		(eventData?: any) => {
			if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
				if (!mixpanelInitialized) {
					mixpanel.init(MIXPANEL_TOKEN, {
						api_host: MIXPANEL_API_HOST,
					});
					setMixpanelInitialized(true);
				}
				try {
					mixpanel.track(eventName, eventData ?? data);
				} catch (e) {
					sendErrorToWebhook(
						`ERROR CAUGHT WHILE TRACKING MIXPANEL EVENT ${eventName}:\n${JSON.stringify(
							e
						)}`
					);
				}
			} else {
				console.info("WOULD FIRE MIXPANEL EVENT: ", eventName, {
					data: eventData ?? data,
				});
			}
		},
		[data, eventName, mixpanelInitialized]
	);
};

export const useEventTracker = () => {
	const { mixpanelInitialized, setMixpanelInitialized } = useAppContext();

	return useCallback(
		throttleFunctionByArgs(
			({ eventName, data }: { eventName: string; data?: Dict }) => {
				if (process.env.NODE_ENV === "production" && onOwnwellDotCom()) {
					if (!mixpanelInitialized) {
						mixpanel.init(MIXPANEL_TOKEN, {
							api_host: MIXPANEL_API_HOST,
						});
						setMixpanelInitialized(true);
					}
					try {
						mixpanel.track(eventName, data);
					} catch (e) {
						sendErrorToWebhook(
							`ERROR CAUGHT WHILE TRACKING MIXPANEL EVENT ${eventName}:\n${JSON.stringify(
								e
							)}`
						);
					}
				} else {
					console.info("WOULD FIRE MIXPANEL EVENT: ", eventName, {
						data: data,
					});
				}
			},
			100
		),
		[mixpanelInitialized]
	);
};

export const TrackEvent: React.FC<
	Parameters<ReturnType<typeof useEventTracker>>[0]
> = ({ eventName, data }) => {
	const trackEvent = useEventTracker();
	useEffect(() => {
		trackEvent({ eventName, data });
	}, []);
	return null;
};
